import React from 'react';

import { Layout } from '../../components/layouts';
import { SEO } from '../../components/common';
import {
  StorageBaseWarrantyHeroSection,
  StorageBaseWarrantyIntroSection,
  StorageBaseWarrantyContentSection,
  StorageBaseWarrantyFooterSection,
} from '../../components/sections';

const StorageBaseWarranty: React.FC = () => {
  return (
    <Layout>
      <SEO title="The Storage Bed" />
      <StorageBaseWarrantyHeroSection />
      <StorageBaseWarrantyIntroSection />
      <StorageBaseWarrantyContentSection />
      <StorageBaseWarrantyFooterSection />
    </Layout>
  );
};

export default StorageBaseWarranty;
